<template>
  <b-card title="Data Pegawai">
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Import Foto
              </h5>
            </b-col>
            <b-col cols="10">
              <b-form-group label="Upload File" label-for="files" label-cols-md="2">
                <b-form-file id="extension" accept=".zip" />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col offset-md="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
                Upload
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row>
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-no-wrap">
                  Keterangan
                </th>
                <th class="border-b-2 dark:border-dark-5 text-right whitespace-no-wrap">
                  Total File
                </th>
                <th class="border-b-2 dark:border-dark-5 text-right whitespace-no-wrap">
                  Sukses
                </th>
                <th class="border-b-2 dark:border-dark-5 text-right whitespace-no-wrap">
                  Error
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-no-wrap">
                    Format File Tidak Sesuai
                  </div>
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  15
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  10
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
                  5
                </td>
              </tr>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-no-wrap">
                    Ukuran File Melebihi Batas Maksismal
                  </div>
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  25
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  10
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
                  15
                </td>
              </tr>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-no-wrap">
                    Format File Tidak Sesuai
                  </div>
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  15
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32">
                  10
                </td>
                <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
                  5
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup, BForm, BButton,
  BFormFile,
} from 'bootstrap-vue'
// import { codeIcon } from './code'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormGroup,
    BForm,
    BButton,
    BFormFile,
  },
  methods: {
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/studentimportphotos', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Orangtua Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-orangtua' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Orangtua Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
